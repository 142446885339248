import Vue from 'vue'
import BaseLayout from './components/customer/BaseLayout.vue';
import vuetify from './plugins/vuetify'
import router from './customer-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.config.productionTip = false

const moment = require('moment')
require('moment/locale/pl')
 
Vue.use(require('vue-moment'), {
    moment
});

Vue.use(require('vue-moment'));

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
    position: 'bottom-right'
});

import DynamicModal from './plugins/DynamicModal/ModalPlugin';
Vue.use(DynamicModal);

Vue.use(VueAxios, axios);

import Nl2br from 'vue-nl2br'
Vue.component('nl2br', Nl2br);

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
Vue.filter('money', function (value) {
    return moneyFormat.format(value);
})

if (process.env.VUE_APP_GTM) {
    let tag = document.createElement("script");
    tag.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=" + process.env.VUE_APP_GTM);
    document.head.appendChild(tag);

    window.dataLayer = window.dataLayer || [];
    window.gtag = function() { window.dataLayer.push(arguments); }
    window.gtag('js', new Date());
    window.gtag('config', process.env.VUE_APP_GTM, { 'allow_enhanced_conversions': true });
    if (process.env.VUE_APP_GA3) window.gtag('config', process.env.VUE_APP_GA3);
    if (process.env.VUE_APP_GA4) window.gtag('config', process.env.VUE_APP_GA4);
}

if (process.env.VUE_APP_WP_PIXEL_ID) {
    let tag = document.createElement("script");
    let script = `!function(d,m,e,v,n,t,s){d['WphTrackObject'] = n;
        d[n] = window[n] || function() {(d[n].queue=d[n].queue||[]).push(arguments)},
        d[n].l = 1 * new Date(), t=m.createElement(e), s=m.getElementsByTagName(e)[0],
        t.async=1;t.src=v;s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://pixel.wp.pl/w/tr.js', 'wph');
        wph('init', '${process.env.VUE_APP_WP_PIXEL_ID}');`;

    tag.innerHTML = script;
    document.head.appendChild(tag);
}

if (process.env.VUE_APP_FB_PIXEL_ID) {
    let tag = document.createElement("script");
    let script = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.VUE_APP_FB_PIXEL_ID}');
        fbq('track', 'PageView');`

    tag.innerHTML = script;
    document.head.appendChild(tag);
}

Vue.prototype.$copyToClipboard = function(text, notify) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    if (notify && this.$toast) {
        this.$toast.info("Skopiowano do schowka");
    }
}

new Vue({
    router,
    vuetify,
    render: h => h(BaseLayout)
}).$mount('#app')
