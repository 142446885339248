<template>
    <v-dialog v-model="visible" v-bind="apperanceOptions" @keydown.esc="cancel" @click:outside="cancel">
        <component v-if="dialogComponent" :is="dialogComponent" v-bind="dialogProps" @close="onComponentClose" />
    </v-dialog>
</template>

<script>
import Modal from './ModalPlugin';

export default {
    props: {
    },
    data() {
        return {
            visible: false,
            resolve: null,
            reject: null,

            onConfirm: {},
            apperanceOptions: { 'max-width': 600 },
            dialogComponent: null,
            dialogProps: {}
        }
    },

    methods: {
        show(component, params, dialogOptions) {
            this.dialogComponent = null;
            this.apperanceOptions = dialogOptions || { 'max-width': 600 };
            this.$nextTick(() => {
                this.visible = true;
                this.dialogComponent = component;
                if (params) this.dialogProps = params;
            });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        cancel() {
            this.resolve(false);
            this.visible = false;
        },

        onComponentClose(result) {
            this.resolve(result);
            this.visible = false;
        }
    },

    mounted() {
        // Modal.instance = this;
    },

    beforeMount() {
        Modal.instance = this;
        // Modal.EventBus.$on('show', (component, params) => {})
    }
}
</script>