import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import { preset } from 'vue-cli-plugin-vuetify-preset-shrine/preset'
import { preset } from '../../theme-preset/preset'
Vue.use(Vuetify);



export default new Vuetify({
    preset,
    theme: {
        themes: {
            // light: {
            //     primary: '#22ff33',
            //     secondary: '#ff2233',
            //     accent: '#2233ff',
            // },
        },
    },
});
