<template>
    <v-app>
        <v-app-bar
            app
            height="56"
            style="background: #f7f7f7;"
        >
            <div class="d-flex align-center">
                <span  class="text-h6 font-weight-light" style="color:#b67b9f">Panel zarzadznia zamówieniem</span>
            </div>

            <v-spacer></v-spacer>

     
        </v-app-bar>

        <v-main style="background-color:#f9f9f9;">
            <app-modal/>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
// import Order from './components/Order';

export default {
    name: 'BaseLayout',

    components: {
        // Order,
    },

    data: () => ({
    //
    }),

    created() {
        let orderUUID = localStorage.getItem('amelia-order-uuid');
    }
};
</script>
<style lang="scss" scoped>
@import '../../sass/variables.scss';
.w-green {
    color: $w-green;
}
.b-pink {
background-color: $w-pink;
}
</style>