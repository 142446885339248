import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/cart',
        },
        {
            name: 'order',
            meta: { title: "Order" },
            path: '/order/:orderUUID',
            component: () => import('@/components/customer/Order'),
        },
        {
            name: 'cart',
            meta: { title: "Cart" },
            path: '/cart',
            component: () => import('@/components/customer/Cart'),
        },
        {
            name: 'product-details',
            meta: { title: "Product Detalis" },
            path: '/product-details/:orderUUID/:cartPosId',
            component: () => import('@/components/customer/ProductCard'),
        }
    ]
})
